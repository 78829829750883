@import "buttons";
@import "footer";
@import "backgrounds";

* {
  font-family: 'Source Sans Pro', Sans-serif;
}

body {
  color: $dark-gray;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#body-wrapper {
  min-height: calc(100vh - 755px);
}

.to-top-btn {
  display: none;
  width: 68px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 60px;
  right: 0;
  z-index: 9999999;

  .bi-chevron-up {
    font-weight: bold;
    font-size: 30px;
  }
}

.to-top-btn-wrapper:hover {
  background-color: $primary-light;
  transition: background-color ease .2s;
}

.padding-100 {
  padding: 52px 0;
}

.pt-100 {
  padding-top: 52px;
}

.primary-color {
  color: $primary;
}

* {
  word-break: break-word;
  hyphens: auto;
}

.win-blue {
  color: $winto_dark_blue;
}

.text-image {
  img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 10px;
  }
}

.img-cover {
  object-fit: cover !important;
}

.img-contain {
  object-fit: contain !important;
}

.rex-breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  margin-right: auto;
  margin-left: 90px;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li::after {
    content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='30px' viewBox='-6 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Uploaded to SVGRepo https://www.svgrepo.com --%3E%3Ctitle%3Eangle-right%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Vivid.JS' stroke='none' stroke-width='1' fill='%23E0E0E0' fill-rule='evenodd'%3E%3Cg id='Vivid-Icons' transform='translate(-442.000000, -724.000000)'%3E%3Cg id='Icons' transform='translate(37.000000, 169.000000)'%3E%3Cg id='angle-right' transform='translate(390.000000, 546.000000)'%3E%3Cg transform='translate(15.000000, 9.000000)' id='Shape'%3E%3Cpolygon fill='%23E0E0E0' points='3 0 18 15 3 30 0 27 12 15 0 3'%3E%3C/polygon%3E%3Cpath d='M5.5,8.5 L8.5,5.5 L3,0 L0,3 L5.5,8.5 Z M5.5,21.5 L0,27 L3,30 L8.5,24.5 L5.5,21.5 Z' fill='%23E0E0E0'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    margin-left: 10px;
    height: 30px;
  }

  a {
    text-decoration: none;
    padding: 0 10px;
    color: $dark-gray;

    &:hover {
      color: $primary;
    }
  }

  li:last-child {
    a {
      padding-right: 0;
    }
  }

  li:last-child::after {
    content: '';
  }
}

.mobile-breadcrumbs {
  .rex-breadcrumb {
    display: none;
  }
}

.nav-anchor {
  display: block;
  position: absolute;
  top: -180px;
  visibility: hidden;
}

.job-nav-anchor {
  display: block;
  position: relative;
  top: -265px;
  visibility: hidden;
}

.errorLink {
  color: $white;
  font-weight: bold;
  transition: all ease .3s;
}

.errorLink:hover {
  color: $black !important;
}

@media (max-width: 1300px) {
  .rex-breadcrumb {
    margin-left: 20px;
  }
}

@media (max-width: 1200px) {
  .rex-breadcrumb {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 991px) {
  .rex-breadcrumb {
    display: none;
  }

  .mobile-breadcrumbs {
    .rex-breadcrumb {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding-bottom: 40px;
      padding-left: 30px;
      padding-right: 30px;
      margin-left: 0;
    }
  }
}

@media (max-width: 767px) {
  .mobile-breadcrumbs {
    .rex-breadcrumb {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      flex-wrap: wrap;

      a {
        padding: 0 5px;
      }

      li::after {
        margin-left: 5px;
      }
    }
  }

  .nav-anchor {
    top: -100px;
  }
}

@media (max-width: 576px) {

  .col-12,
  .col-6 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.lds-roller {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
  display: none;
  z-index: 9;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $primary;
  margin: -4px 0 0 -4px;
  z-index: 9;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spacer {
  height: 30px;
}