.upcoming-events-slider {
  //height: 465px;
  padding: 75px 0;
  background-color: $lightest-gray;

  .slider-header {
    margin-bottom: 80px;
    .col-12 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .swiper {
    padding-bottom: 40px;
    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0;
    }
  }

  .swiper-wrapper {
    .swiper-slide {
      padding: 0 15px;
    }
    a {
      text-decoration: none;
    }
    h5 {
      color: $primary;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    p {
      color: $dark-gray;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

}
//
//@media (min-width: 992px) {
//  .upcoming-events-slider {
//    .swiper-pagination {
//      display: none;
//    }
//  }
//}

@media (max-width: 991px) {
  .upcoming-events-slider {
    .slider-header {
        max-width: 100%;

      .col-12 {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 30px;
      }
    }
    .swiper {
      padding-bottom: 180px;

      .swiper-pagination {
        padding: 0 15px;
        bottom: 110px;
      }

      .winto-btn {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
      }
    }
  }
}