// Primary Buttons
.primary-btn {
  border: 2px solid $primary;
  color: $white;
  background-color: $primary;
}

.primary-btn:hover::before {
  background-color: $primary_light;
  border-color: $primary-light;
  color: $white;
}

.primary-btn:hover::before,
.primary-btn:hover::after,
.primary-btn:hover {
  border-color: $primary-light;
  color: $white;
}

// Secondary Buttons
.secondary-btn {
  border: 2px solid $white;
  color: $primary;
  background-color: $white;
}

.secondary-btn:hover {
  color: $primary;
  border-color: $white;
}

.secondary-btn:hover::before {
  background-color: $white;
  border-color: $white;
  color: $primary;
}

.secondary-btn:hover::after {
  border-color: $white;
}

// Primary Transparent Buttons
.transparent-primary-btn {
  border: 2px solid $primary;
  color: $primary;
  background-color: transparent;
}

.transparent-primary-btn:hover::before {
  background-color: $primary_light;
  border-color: $primary-light;
  color: $white;
}

.transparent-primary-btn:hover::before,
.transparent-primary-btn:hover::after,
.transparent-primary-btn:hover {
  border-color: $primary-light;
  color: $white;
}

// Secondary Transparent Buttons
.transparent-secondary-btn {
  border: 2px solid $white;
  color: $white;
  background-color: transparent;
}

.transparent-secondary-btn:hover {
  color: $white;
  border-color: $primary-light;
}

.transparent-secondary-btn:hover::before {
  background-color: $primary_light;
  border-color: $primary-light;
  color: $white;
}

.transparent-secondary-btn:hover::after {
  border-color: $primary-light;
}

// Primary Transparent Buttons
.transparent-gray-btn {
  border: 2px solid $dark-gray;
  color: $dark-gray;
  background-color: transparent;
}

.transparent-gray-btn:hover::before {
  background-color: $primary;
  border-color: $primary;
  color: $white;
}

.transparent-gray-btn:hover::before,
.transparent-gray-btn:hover::after,
.transparent-gray-btn:hover {
  border-color: $white;
  color: $white;
}

// Winto Button
.winto-btn:hover::before {
  transition: width ease .3s;
}

.winto-btn {
  width: max-content;
  position: relative;
  height: 60px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: none;
  border-right: none;
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  font-style: italic;
  padding: 15px 30px;
  text-decoration: none;

  span {
    z-index: 99;
  }
}

.winto-btn::before {
  content: '';
  position: absolute;
  left: -9px;
  top: -2px;
  width: 40px;
  height: 60px;
  border: inherit;
  background-color: inherit;
  border-right-width: 0;
  transform: skewX(-10deg);
  font-weight: bold;
  z-index: 5;
}

.winto-btn::after {
  content: '';
  position: absolute;
  right: -9px;
  top: -2px;
  width: 40px;
  height: 60px;
  border: inherit;
  background-color: inherit;
  transform: skewX(-10deg);
}

.winto-btn:hover::before {
  width: calc(100% + 17px);
}

// Primary Before After Borders
.primary-btn::before {
  border-left: 2px solid $primary;
}

.primary-btn::after {
  border-right: 2px solid $primary;
}

// Secondary Before After Borders
.secondary-btn::before {
  border-left: 2px solid $white;
}

.secondary-btn::after {
  border-right: 2px solid $white;
}

// Primary Transparent Before After Borders
.transparent-primary-btn::before {
  border-left: 2px solid $primary;
}

.transparent-primary-btn::after {
  border-right: 2px solid $primary;
}


// Secondary Transparent Before After Borders
.transparent-secondary-btn::before {
  border-left: 2px solid $white;
}

.transparent-secondary-btn::after {
  border-right: 2px solid $white;
}

// gray Transparent Before After Borders
.transparent-gray-btn::before {
  border-left: 2px solid $dark-gray;
}

.transparent-gray-btn::after {
  border-right: 2px solid $dark-gray;
}

.special-btn {
  padding-right: 60px;

  .bi {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    transition: right ease .3s;
    font-size: 32px;
  }
}

.special-btn.clear-btn {
  margin-left: unset;
  padding-left: 0;
  padding-right: 50px;
  font-weight: 700;
  font-style: italic;
}

.special-btn.clear-btn::before,
.special-btn.clear-btn::after {
  display: none;
}

.special-btn:hover {
  .bi {
    right: 0;
    transition: right ease .3s;
  }
}

.gender-select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  .gender-btn {
    padding: 7px 21px;
  }

  .gender-btn,
  .gender-btn:before,
  .gender-btn:after {
    height: 40px;
  }

  .gender-btn:nth-child(2),
  .gender-btn:last-child {
    margin-left: 15px;
  }

  .active-gender {
    background-color: $primary;
    color: $white;
  }
}

#filter_btn {
  position: fixed;
  top: 350px;
  right: 50px;
  z-index: 99;
  height: 80px;
  font-size: 24px;
  display: none;
}

#filter_btn:before {
  height: 80px;
}

#filter_btn:after {
  height: 80px;
}

@media (max-width: 767px) {
  #filter_btn {
    right: 0;
  }
}

.side-buttons {
  position: fixed;
  right: 0;
  top: 70vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 999;

  span {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    text-align: center;
  }

  .plan-btn:before,
  .catalog-btn:before {
    transform: unset;

  }

  .catalog-btn,
  .plan-btn {
    padding: 5px;
    font-size: 16px;
    width: 180px;
  }

}

@media (max-width: 991px) {
  // .side-buttons {
  //   .plan-btn {
  //     height: 80px;
  //     width: 83px;
  //   }

  //   .plan-btn.winto-btn:before,
  //   .plan-btn.winto-btn:after {
  //     height: 80px;
  //   }

  //   .catalog-btn {
  //     width: 83px;
  //     height: 104px;
  //   }

  //   .catalog-btn.winto-btn:before,
  //   .catalog-btn.winto-btn:after {
  //     height: 104px;
  //   }

  //   .catalog-btn,
  //   .plan-btn {
  //     padding: 5px;
  //     font-size: 16px;
  //   }
  // }
  .side-buttons {
    display: none;
  }
}

@media (max-width: 991px) {
  .side-buttons {

    .plan-btn,
    .catalog-btn,
    .plan-btn.winto-btn:before,
    .plan-btn.winto-btn:after,
    .catalog-btn.winto-btn:before,
    .catalog-btn.winto-btn:after {
      height: 80px;
    }

    .catalog-btn {
      span {
        span {
          display: none;
        }
      }

      span:after {
        content: 'Aussteller';
      }
    }

    .catalog-btn,
    .plan-btn {
      padding: 5px;
      font-size: 16px;
      padding-right: 12px;
      width: 90px;
    }
  }
}