.contact-prompt {
  form {
    display: flex;
    flex-direction: column;
  }
  .form-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .input-group {
    width: 48%;
    display: inline-block;
  }

  .checkbox-wrapper .checkbox-border input:checked+.custom-checkbox {
    background-color: $white;
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  h2,
  h3,
  h4,
  label,
  input, {
    color: $white !important;
  }

  h2 {
    margin-bottom: 80px;
  }

  input[type=text]:focus+label {
    color: $secondary !important;
  }

  input[type=text]:focus,
  .input-group:hover input{
    border-color: $secondary;
  }

  .winto-btn {
    margin-top: 50px;
  }
}

@media (max-width: 991px) {
  .contact-prompt {
    h2 {
      margin-bottom: 30px;
    }
    .winto-btn {
      margin-right: auto;
    }
    .form-inputs {
      flex-direction: column;
    }
    .input-group {
      width: 100%;
      display: block;
    }
    .col-lg-3 {
      display: flex;
    }

    img {
      width: 150px;
      height: 150px;
      margin: auto;
      margin-bottom: 30px;
    }
  }
}