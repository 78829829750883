.job-offers {
  .row:first-child {
    margin-bottom: 100px;
  }
  .row:nth-child(2) {
    margin-bottom: 50px;
  }
  a {
    text-decoration: none;
  }
  h3 {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .winto-btn {
    padding: 3px 9px;
    height: 35px;
  }
  .winto-btn:after,
  .winto-btn:before {
    height: 35px;
  }
  .job-card {
    background-color: $lightest-gray;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 30px;

    .card-body {
      position: relative;
      z-index: 9;
      width: 100%;
      height: 100%;
      padding: 30px 40px;
      padding-right: 120px;
    }

    .bg-animation {
      background-color: $light-gray;
      transform: skew(-10deg);
      width: 0;
      height: 100%;
      transition: width ease .3s;
      position: absolute;
      left: 0;
      top: 0;
      margin-left: -50px;
    }
  }

  .bi-arrow-right {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    color: $primary;
    font-size: 34px;
    transition: right ease .3s;
  }

  .job-card:hover {
    p, h3,{
      color: $primary-light;
      border-color: $primary-light;
    }
    .winto-btn,
    .winto-btn:after,
    .winto-btn:before {
      border-color: $primary-light;
      color: $primary-light;
    }
    .winto-btn:hover {
      span {
        color: $white !important;
      }
    }
    .bi-arrow-right {
      right: 30px;
      transition: right ease .3s;
      color: $primary-light;
    }
    .bg-animation {
      width: 130%;
      transition: width ease .3s;
    }
  }
}