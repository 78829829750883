header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  z-index: 9999;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all ease .3s;

  //ss
  .logo {
    object-fit: contain;
    height: 100px;
  }

  .header-navigation-wrapper {
    display: flex;
    align-items: center;

    a {
      color: $dark-gray;
      font-size: 26px;
      line-height: 36px;
      text-decoration: none;
    }

    a:nth-child(2) {
      margin-left: 40px;
      margin-right: 60px;
    }

    .bi {
      margin-right: 20px;
    }

    .nav-toggler-inner {
      transform: skew(10deg);
    }

    .nav-toggler-wrapper {
      display: inline-block;
      position: relative;
      background-color: $dark-gray;
      width: 165px;
      height: 60px;
      transition: background-color ease .2s;
      transform: skew(-10deg);
    }

    .nav-toggler {
      background-color: #fff;
      width: 161px;
      height: 56px;
      position: absolute;
      top: 2px;
      left: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 22px 36px;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      transform: skew(-10deg);

      .winto-burger {
        height: 16px;
        width: 27px;
        border-top: 2px $dark-gray solid;
        border-bottom: 2px $dark-gray solid;
      }
    }

    .nav-toggler-wrapper:hover {
      background-color: $primary;
      transition: background-color ease .2s;

      .winto-burger {
        border-color: $primary;
        transition: all ease .2s;
      }

      a {
        color: $primary;
      }
    }

    a:hover {
      color: $primary;
    }
  }

  // SIDENAVs
  .side-nav {
    height: calc(100vh - 180px);
    width: calc(750px + 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
    right: -1000px;
    bottom: 0;
    background-color: $primary;
    transition: right ease 300ms;
    transform: skew(-10deg);
    padding: 60px 160px 40px 140px;
    z-index: 99999;

    .sidenav-wrapper {
      transform: skew(10deg);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }

    nav {
      width: 100%;
      overflow: auto;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        overflow: auto;

        .active>a {
          color: $secondary;
        }

        a {
          color: $dark-gray;
          width: 100%;
          text-decoration: none;
          padding: 18px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 26px;
          line-height: 33px;

          .bi-chevron-left {
            display: none;
            margin-right: 10px;
          }
        }

        .main-nav-item>a {
          border-bottom: 2px solid $dark-gray;
          transition: all ease .3s;

          &:hover {
            color: #fff;
          }
        }

        .dropdown {
          display: none;
        }

      }
    }

    .active-nav-item {
      a {
        justify-content: flex-start;
      }
    }

    .bottom-nav {
      list-style: none;
      padding: 0;
      margin: 0;

      .active-lang {
        color: $secondary;
      }

      .bi-chevron-up {
        display: none;
      }

      .dropdown {
        display: none;

        li {
          margin-top: 15px;
        }
      }

      li {
        margin-top: 30px;
        list-style: none;

        a {
          color: $dark-gray;
          text-decoration: none;

          .fa-earth-europe,
          .fa-location-dot,
          .fa-school-flag,
          .fa-envelope,
          .fa-people-group,
          .fa-map,
          .fa-people-line {
            margin-right: 10px;
            width: 24px;
          }

          &:hover {
            color: $white;
          }
        }

      }

    }

  }

  .active-nav.side-nav {
    right: -100px;
    transition: right ease 300ms;
  }
}

#body-wrapper {
  padding-top: 180px;
}

@media (max-width: 991px) {
  header {
    .header-navigation-wrapper {
      a:nth-child(2) {
        margin-left: 35px;
        margin-right: 25px;
      }
    }

    .win-logo {
      height: 60px !important;
    }
  }
}

@media (max-width: 767px) {
  header {
    padding: 20px;
    height: 100px;

    .logo {
      height: 60px;
    }

    .header-navigation-wrapper {
      a:nth-child(2) {
        margin-right: 20px;
        margin-left: 30px;
      }

      .bi {
        margin-right: 0;
      }

      .nav-toggler {
        position: unset;
        width: max-content;
        padding: 0;
        clip-path: unset;
        margin-left: 20px;

        .nav-toggler-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .winto-burger {
          margin-right: 10px;
        }
      }
    }





    .sidenav-wrapper {
      transform: unset !important;
    }

    .side-nav {
      height: calc(100vh - 100px);
      width: 100%;
      right: -100vw;
      transform: unset;
      padding: 60px 20px 30px 20px;
    }

    .side-nav.active-nav {
      right: 0;
    }
  }

  #body-wrapper {
    padding-top: 100px !important;
  }
}



#nav_overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  z-index: 999;
}