$standard_text_color: #333;

$h1_fz: 55px;
$h2_fz: 46px;
$h3_fz: 36px;
$h4_fz: 26px;
$h5_fz: 26px;
$standard_text_fz: 20px;
$mobile-standard_text_fz: 17px;
$small_text_fz: 17px;
$tiny_text_fz: 15px;

$h1_lh: 62px;
$h2_lh: 52px;
$h3_lh: 45px;
$h4_lh: 33px;
$h5_lh: 33px;
$standard_text_lh: 25px;
$small_text_lh: 28px;
$tiny_text_lh: 20px;

$padding: 3.3em 0;