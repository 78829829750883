.company-search {
  input {
    width: 100%;
  }

  .select2 {
    height: auto;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 3px;
  }

  .accordion-body {
    padding-bottom: 30px;
    padding-top: 14px;
  }

  .select2-selection__rendered {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .col-12 {
    margin-bottom: 0;
  }

  .accordion-header button {
    text-transform: uppercase;

    .tiny-text {
      margin-top: 3px;
    }
  }

  .filter-option {
    margin-bottom: 10px;
  }

  .filter-option:last-child {
    margin-bottom: 0;
  }

  .checkbox-wrapper {
    align-items: center;

    .checkbox-border {
      width: 20px;
      min-width: 20px;
      height: 20px;

      .custom-checkbox {
        width: 12px;
        height: 12px;
      }
    }
  }

  .circle-border {
    margin-right: 26px;
  }


  .checked {
    border-color: $primary !important;
  }

  .checked+label {

    color: $primary !important;

  }

  #collapse_company_search_input {

    .input-group,
    .accordion-body {
      padding-top: 0;
    }
  }

  .filter-form-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    flex-wrap: wrap;
    padding-right: 30px;

    a:first-child {
      margin-right: 25px;
    }

    a {
      margin-right: 15px;
    }
  }

  .filters-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    .filter-option {
      width: 33.33%;
      align-items: flex-start;

      label {
        margin-right: 15px;
      }

      .info-btn {
        margin-right: 15px;
        margin-left: auto;
        margin-top: 2px;

        .bi-info-circle-fill {
          color: $primary;
          transition: color ease .3s;
        }
      }

      .info-btn:hover {
        .bi-info-circle-fill {
          color: $primary-light;

        }
      }

      .checkbox-border {
        margin-top: 4px;
      }
    }
  }

}

#companysearch_result,
#estatesearch_result,
#jobsearch_result {

  h4 {
    margin-bottom: 50px;
  }

  .search-result-card {
    background-color: $lightest-gray;
    min-height: 94px;
    padding: 0 25px;
    margin-bottom: 30px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    .left-side,
    .right-side {
      z-index: 4;
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .left-side {
      padding-left: 60px;
      padding-right: 30px;

      .search-result-title {
        position: relative;
        z-index: 4;
        display: flex;
        flex-direction: column;
      }

      .search-result-total {
        position: absolute;
        left: 0;
        z-index: 4;

        .bi-geo-alt-fill {
          color: $yellow;
          font-size: 46px;
          transition: all ease .3s;
        }

        .icon-fill {
          width: 30px;
          height: 30px;
          background-color: $yellow;
          position: absolute;
          top: 11px;
          left: 8px;
          border-radius: 50%;
          z-index: 1;
          transition: all ease .3s;
        }

        .search-result-total-nr {
          position: absolute;
          top: 15px;
          left: 50%;
          z-index: 3;
          color: $black;
          font-size: 14px;
          font-weight: bold;
          transition: all ease .3s;
          transform: translateX(-50%);
        }
      }
    }

    .right-side {
      opacity: 0;
      transition: opacity ease .3s;
      min-width: 280px;
      z-index: 15;

      a {
        text-decoration: none;
        font-size: 17px;
        line-height: 28px;
        color: $primary-light;
        font-weight: bold;
      }

      .search-result-on-the-map {
        margin-right: auto;
        white-space: nowrap;
      }

      .search-result-details-link {
        margin-right: 20px;
        transition: margin-right ease .3s;
        position: relative;
        z-index: 2;

        .bi-arrow-right {
          font-size: 30px;
          transition: all ease .3s;
        }
      }
    }
  }

  .search-result-card-animation {
    background-color: $light-gray;
    transform: skewX(-10deg);
    height: 100%;
    position: absolute;
    left: -40px;
    top: 0;
    bottom: 0;
    width: 0;
    transition: width ease .3s;
    z-index: 1;
  }

  .search-result-details-link-animation {
    background-color: $primary;
    transform: skewX(-10deg);
    height: 100%;
    position: absolute;
    right: -20px;
    top: 0;
    bottom: 0;
    width: 0;
    transition: width ease .3s;
    z-index: 9;
  }

  //*************************************//
  //*************// HOVER //*************//
  //*************************************//

  .search-result-card:hover {

    .left-side {
      .search-result-title {
        color: $primary-light;
      }

      .bi-geo-alt-fill {
        font-size: 50px;
        transition: all ease .3s;
      }

      .icon-fill {
        top: 15px;
        left: 10px;
        transition: all ease .3s;
      }

      .search-result-total-nr {
        top: 17px;
        transition: all ease .3s;
      }
    }

    .right-side {
      opacity: 1;
      transition: opacity ease .3s;

      .search-result-details-link {
        margin-right: 0;
        transition: margin-right ease .3s;
      }
    }

    .search-result-card-animation {
      width: calc(100% + 30px);
      transition: width ease .3s;
    }
  }
}

.active-search-result-animation {
  width: 100%;
  transition: width ease .3s;
}

.search-result-details-link:hover {
  .bi-arrow-right {
    color: $white;
    transition: all ease .3s;
  }
}

@media (max-width: 991px) {

  #companysearch_result,
  #estatesearch_result,
  #jobsearch_result {
    .search-result-card {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .left-side,
      .right-side {
        width: 100%;

        .search-result-title {
          padding-right: 72px;
        }
      }

      .right-side {
        opacity: 1;
        justify-content: space-between;
        padding-top: 0;
        position: unset;

        .search-result-on-the-map {
          margin-left: 60px;
        }

        .search-result-details-link {
          position: absolute;
          top: 25px;
          right: 20px;
        }
      }
    }

    .search-result-card:hover {

      .search-result-card-animation {
        width: 0 !important;
      }
    }
  }

  .cat-name {
    font-weight: bold !important;
  }

  .transport-connection {
    .row {
      .col-12:nth-child(3) {
        align-items: flex-start;
      }
    }
  }

  .container {
    .info-box-wrapper {
      background-color: #f6f6f6;
    }
  }

  .info-box-wrapper {
    .estate-info-box {
      padding: 15px !important;
    }
  }

  .estate-info-box.contact-person-box {
    padding-top: 0 !important;

    h4 {
      margin-top: 10px;
    }
  }

  .building-areas.accordion {
    .accordion-body {
      padding: 0;
    }
  }

  .connections-list:last-child {
    .col-12:last-child {
      margin-bottom: 0 !important;
    }
  }

}

@media (max-width: 767px) {

  #companysearch_form,
  #estate_form,
  #jobsearch_form {
    .filters-wrapper {
      .filter-option {
        width: 100%;
      }
    }
  }
}

@media (max-width: 576px) {

  #companysearch_form,
  #estate_form,
  #jobsearch_form {
    .filter-form-buttons {
      justify-content: center;
      flex-direction: column;

      a,
      button {
        width: calc(100% - 50px);
      }

      a:first-child {
        margin-bottom: 30px;
        margin-right: unset;
      }
    }

    .accordion-button {
      .tiny-text {
        font-size: 12px;
      }
    }
  }

  #companysearch_result,
  #estatesearch_result,
  #jobsearch_result {
    .search-result-card {

      .left-side {
        padding-right: 0;
        padding-left: 40px;

        .search-result-total {
          left: -15px;
        }
      }

      .right-side {
        .search-result-on-the-map {
          margin-left: 0;
        }

        .search-result-details-link {
          margin-right: 0;
        }
      }
    }
  }

  .flex-acc-header {
    display: flex;
    flex-direction: column;
    color: #acacac !important;

    .tiny-text {
      margin-left: 0 !important;
    }
  }

  .company-search-details-header,
  .job-details-header {
    h1 {
      hyphens: auto !important;
    }
  }
}

.company-search-details-header,
.job-details-header {
  img {
    max-height: 100px;
  }

  h1 {
    margin-bottom: 0;
    hyphens: manual;
  }
}

.company-search-subnav {
  margin: 0 50px;
  background-color: $lightest-gray;
  position: relative;

  .transparent-primary-btn,
  .transparent-primary-btn::after,
  .transparent-primary-btn::before {
    border-color: transparent;
  }

  .primary-btn {
    pointer-events: none;
  }
}

.company-search-subnav::before {
  content: '';
  position: absolute;
  left: -9px;
  top: 0;
  width: 40px;
  height: 60px;
  border: inherit;
  background-color: inherit;
  border-right-width: 0;
  transform: skewX(-10deg);
  font-weight: bold;
  z-index: 5;
}

.company-search-subnav::after {
  content: '';
  position: absolute;
  right: -9px;
  top: 0;
  width: 40px;
  height: 60px;
  border: inherit;
  background-color: inherit;
  transform: skewX(-10deg);
}

.info-box-wrapper {
  .col-12 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-box {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px;
    background-color: $lightest-gray;

    p {
      color: $primary;
    }

    a {
      text-decoration: none;
      margin-bottom: 5px;
    }
  }
}

@media (max-width: 991px) {
  .company-search-subnav {
    display: none;
  }

  #companysearch_form,
  #estate_form,
  #jobsearch_form {
    .filter-form-buttons {
      padding-right: unset;
    }
  }
}


// ESTATE SEARCH
.estate-search {
  .accordion-button {
    pointer-events: none;
  }

  .accordion-body {
    padding-left: 20px;
    padding-right: 20px;
  }
}

// ESTATE DETAILS
.estate-info-box {
  width: 100%;
  margin-bottom: auto;

  .col-12 {
    justify-content: flex-start;
    align-items: flex-start;
  }

  h4 {
    padding-left: 0;
  }

  h4:before {
    content: '';
  }

  p,
  a {
    padding-left: 20px;
  }
}

.building-areas.accordion {
  .accordion-button {
    background-color: $primary;
    color: $white;

    .bi-caret-down-fill {
      transform: rotate(180deg);
      transition: all ease .3s;
    }
  }

  .accordion-button.collapsed {
    .bi-caret-down-fill {
      transform: unset;
    }
  }

  .accordion-collapse:last-child {
    .accordion-body {
      padding-bottom: 0;
    }
  }

  .accordion-item:nth-last-child(2) {
    margin-bottom: 0;
  }
}

.transport-connection,
.technical-infrastructure,
.regional-profile,
.download-section {
  .estate-info-box {
    .col-12 {
      flex-direction: column;
    }
  }
}

.accordion .estate-info-box {
  p {
    margin-bottom: 0 !important;
    padding-left: 0 !important;
  }
}

.estates-map.gmap {
  .gm-style-iw-d div {
    font-size: 20px;
  }
}

.transport-connection {
  .col-12:nth-child(3) {
    display: flex;
    align-items: center;
    text-align: center;
  }
}

.technical-infrastructure,
.regional-profile,
.transport-connection,
.download-section,
.building-areas .accordion-body {
  color: $primary;
}

.estate-info-box .row:last-child {
  margin-bottom: 0 !important;
}


// JOB SEARCH
.job-tag {
  padding: 0;
  height: 32px;
  font-size: 20px;
  font-weight: bold;
  pointer-events: none;
  cursor: text;
  margin-top: 15px;
  margin-right: 15px;
  text-transform: uppercase;
}

.job-tag:before,
.job-tag:after {
  height: 32px;
}

.sticky-job-nav {
  position: fixed;
  top: 180px;
  width: 100%;
}

.job-nav {
  padding-top: 20px;
  z-index: 999;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .job-nav-item {
      padding-bottom: 20px;
      margin-right: 60px;

      .job-nav-link {
        text-decoration: none;
        color: $standard_text_color;
        padding-left: 0;
        margin-bottom: 0;
      }
    }

    .job-nav-item.active-job-nav-item {
      border-bottom: 2px solid $yellow;

      .job-nav-link {
        color: $primary;
      }
    }
  }
}

// JOB SEARCH
#jobsearch_result {
  .search-result-card {
    cursor: pointer;

    .left-side {
      padding-left: 0;
      width: 100%;
    }

    .right-side {
      min-width: unset;
      z-index: 10;
    }
  }

  .search-result-card:hover {
    .search-result-details-link {
      .bi-arrow-right {
        color: $white;
        transition: all ease .3s;
      }
    }
  }
}

.job-form-prompt {
  .col-12 {
    padding: 85px 117.5px;

    h3 {
      margin-bottom: 30px;
    }
  }
}

.job-head-info {
  display: flex;
  align-items: center;

  h4 {
    padding-left: 0;
  }

  h4:before {
    content: none;
  }
}

@media (max-width: 991px) {
  .job-form-prompt {
    .col-12 {
      padding: 30px;
    }
  }
}

.job-search-box {
  background-color: $lightest-gray;
  padding: 15px;

  p {
    color: #939393;
  }

  a {
    color: #a3a3a3;
  }

  h4 {
    padding-left: 0;
  }

  h4:before {
    content: none;
  }


}

.custom-pl {
  padding: 0 15px;

}

.job-info,
.job-description,
.job-requirements,
.job-application-info,
.job-application-contact-info {
  .standard-bold-text {
    color: $primary;
    margin-bottom: 10px;
  }
}

@media (max-width: 1200px) {
  .job-nav {
    display: none;
  }
}

@media (max-width: 991px) {
  .job-head-info {
    flex-direction: column;
    align-items: flex-start;

    span {
      display: none;
    }
  }

  .custom-pl {
    padding: unset;
  }

  .job-details-header {
    padding-left: 0;
    padding-right: 0;
  }

  .job-details-header,
  .company-search-details-header {

    .h1-bold {
      font-size: 42px;
      line-height: 52px;
    }
  }

  .job-info,
  .job-requirements,
  .job-application-info,
  .job-application-contact-info {

    .container {
      padding-left: unset;
      padding-right: unset;

      .row {
        margin-left: unset;
        margin-right: unset;

        .col-3,
        .col-4,
        .col-6,
        .col-8,
        .col-12 {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        .p-15 {
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
      }
    }

  }

  .p-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

}

.job-location-info {
  text-decoration: none;
  font-size: 17px;
  line-height: 28px;
  color: #ACACAC;
  font-weight: bold;
}

.company-search-images {

  .row {
    justify-content: center;
  }

  //.col-12:nth-child(even).offset-lg-1 {
  //  margin-left: unset;
  //}

  .col-12:last-child {
    .image-text {
      margin-bottom: 0;
    }
  }

  .company-search-image {
    width: 100%;
    object-fit: cover;
    padding-top: 75%;
    margin-top: -75%;
  }

  .image-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 24px;
    margin-top: 5px;

    p,
    h5 {
      font-size: 14px;
      line-height: 20px;
      font-style: italic;
      margin-bottom: 0;
    }
  }
}