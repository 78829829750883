.icon-cards {
  background-color: $lightest-gray;

  h2 {
    margin-bottom: 80px;
  }

  p {
    color: $dark-gray;
    margin-bottom: 15px;
    line-height: 30px;
    padding-right: 6px;
  }

  img {
    width: 100%;
    margin-top: 5px;
  }

  .col-lg-4:last-child {
    p {
      padding-right: 0;
    }
  }
}

@media (max-width: 991px) {
  .icon-cards {
    h2 {
      margin-bottom: 50px;
    }
    .icon-card {
      margin-bottom: 40px;

      img {
        max-width: 60px;
        margin-bottom: 20px;
      }
    }
  }
}