.gmap {
  height: 590px;
  background: $primary;
  position: relative;

  .gm-style-iw-chr {
    position: absolute;
    top: 0;
    right: 0;
    width: max-content;
    .gm-ui-hover-effect>span {
      background-color: #fff;
    }
  }

  .gmnoprint {
    display: none;
  }

  .gm-style-iw-d {
    max-height: 300px !important;
    height: 200px;

    div {
      height: 100%;
      color: $white;
    }
  }

  .poi-info-window div,
  .poi-info-window a {
    background-color: $primary;
    color: $white;
  }

  .map-info {

    padding: 30px;

    h5 {
      color: $white;
      margin-bottom: 30px;
    }

    p:first-child {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 0;
    }
  }

  #body-content {
    display: flex;
    flex-direction: column;
  }

  .container,
  .row {
    height: 100%;
  }

  h1 {
    color: $white;
    margin-bottom: 30px;
    padding-right: 30px;
  }

  .new-btn {
    width: 270px;
    height: 270px;
    margin-left: auto;
    padding: 20px;
  }

  .gmaps-consent {
    z-index: 9;
    margin-top: 30px;
  }

  .gmap-placeholder {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
  }

  img {
    object-fit: cover;
  }

  #map {
    height: 590px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .map-info {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) skew(-10deg);
    z-index: 9;
    background-color: $primary;

    p,
    a {
      color: $white;
      text-decoration: none;
      text-align: center;
    }

    .text-wrapper {
      transform: skew(10deg);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 30px;
    }
  }

  .gm-style .gm-style-iw-c {
    max-height: 400px !important;
    max-width: 430px !important;
    width: 380px;
    background-color: $primary;
    padding: 30px !important;
    box-shadow: none;
    border-radius: 0;
    transform: translate3d(-120%, -80%, 0);
  }

  .gm-style-iw-d {
    max-height: 100% !important;
    height: 100% !important;
    overflow: auto !important;

    h4,
    p,
    a {
      color: $white;
      text-decoration: none;
    }

    .secondary-btn {
      color: $primary;
    }

    a {
      margin-bottom: 5px;
    }

    svg,
    .bi-telephone:before {
      width: 24px;
    }
  }

  .gm-style-iw-d+button {
    display: none;
  }

  .gm-style-iw-d+button {
    top: 15px !important;
    right: 15px !important;

    span {
      background-color: $white !important;
      width: 20px !important;
      height: 20px !important;
    }
  }

  .tel-link {
    pointer-events: none;
    display: block;
  }

}

@media (max-width: 991px) {
  .gmap {
    .map-info {
      left: 0;
      right: 0;
      transform: translateY(-50%) skew(0);

      .text-wrapper {
        transform: skew(0);
      }
    }

    .gm-style {
      .gm-style-iw-c {
        transform: translate3d(-50%,-100%,0)
      }
    }
  }
}

@media (max-width: 1060px) {
  .gmap {
    height: calc(100vh - 152px);

    #map {
      height: calc(100vh - 152px);
    }

    .map-info {
      left: 0;
      right: 0;
      transform: translateY(-50%) skew(0);

      .text-wrapper {
        transform: skew(0);
      }
    }
  }

  .gm-style>div:nth-child(2)>div:nth-child(2)>div:nth-child(1) {
    left: 0 !important;
    top: 0 !important;
    //position: fixed !important;

    .gm-style-iw-c {
      transform: unset;
      top: 200px;
      max-width: 380px !important;
      width: 100%;
    }
  }
}

a.gmap-anchor {
  display: block;
  position: absolute;
  top: -180px;
  visibility: hidden;
}

@media (max-width: 767px) {
  .gmap {
    height: calc(100vh - 100px);

    #map {
      height: calc(100vh - 100px);
    }

    .tel-link {
      pointer-events: unset;
    }
  }

  .gm-style>div:nth-child(2)>div:nth-child(2)>div:nth-child(1) {
    position: absolute !important;

    .gm-style-iw-c {
      left: 0;
      position: fixed;
      top: calc(100vh - 490px);
      width: 100vw;
      max-width: 100vw !important;
    }
  }
}