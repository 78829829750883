footer {
  height: 575px;
  position: relative;
  overflow-x: hidden;
  margin-top: auto;

  h4 {
    margin-bottom: 30px;
  }

  h3 {
    word-break: keep-all;
    hyphens: none;
  }

  .dark-bg {
    height: 100%;
    width: 100%;
    background-color: $primary;
    z-index: 1;
    position: absolute;
  }

  .light-bg {
    width: 24vw;
    z-index: 2;
    background-color: $primary-light;
    position: absolute;
    bottom: 0;
    right: -50px;
    height: 100%;
    transform: skew(-10deg);
  }

  .container {
    height: 100%;
  }

  .footer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .footer {
    z-index: 3;
    position: relative;
    padding-top: 125px;


    * {
      color: $dark-gray;
    }
  }

  .sub-footer {
    z-index: 3;
    position: relative;
    padding-bottom: 55px;

    * {
      color: $dark-gray;
      hyphens: none;
    }

    .col-12 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  a {
    text-decoration: none;
    display: block;
  }

  a:hover {
    text-decoration: underline;
  }

  .icon {
    width: 24px !important;
    margin-right: 17px;
  }

  .company-info {
    p {
      line-height: 30px;
      hyphens: none;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 991px) {
  footer {
    height: auto;

    .light-bg {
      transform: skew(-10deg);
      right: -120px;
    }

    .footer {
      padding-top: 50px;

      .col-12 {
        margin-bottom: 50px;
      }

      .col-12:nth-child(3) {
        display: flex;
        flex-direction: column;
      }
    }

    .sub-footer {
      margin-top: 80px;

      .col-12:first-child {
        flex-direction: column;
        align-items: flex-start;

        p {
          margin-left: 0 !important;
          margin-top: 30px;
        }
      }

      .col-lg-4 {
        margin-top: 30px;
      }
    }
  }
}