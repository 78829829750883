@import "fonts";

h1,
h2,
h3,
h4 {
  color: $primary;
  font-weight: 700;
  font-style: italic;
}

.h1,
h1 {
  font-size: $h1_fz;
  line-height: $h1_lh;
}

.h1-light {
  font-size: $h1_fz;
  font-weight: 300;
  line-height: $h1_lh;
}

.h1-medium {
  font-size: $h1_fz;
  font-weight: 500;
  line-height: $h1_lh;
}

.h1-bold {
  font-size: $h1_fz;
  font-weight: 700;
  line-height: $h1_lh;
}

.h2,
h2 {
  font-size: $h2_fz;
  line-height: $h2_lh;
}

.h2-light {
  font-size: $h2_fz;
  font-weight: 300;
  line-height: $h2_lh;
}

.h2-medium {
  font-size: $h2_fz;
  font-weight: 500;
  line-height: $h2_lh;
}

.h2-bold {
  font-size: $h2_fz;
  font-weight: 700;
  line-height: $h2_lh;
}

.h3,
h3 {
  font-size: $h3_fz;
  line-height: $h3_lh;
}

.h3-light {
  font-size: $h3_fz;
  font-weight: 300;
  line-height: $h3_lh;
}

.h3-medium {
  font-size: $h3_fz;
  font-weight: 500;
  line-height: $h3_lh;
}

.h3-bold {
  font-size: $h3_fz;
  font-weight: 700;
  line-height: $h3_lh;
}

.h4,
h4 {
  font-size: $h4_fz;
  line-height: $h4_lh;
  position: relative;
  padding-left: 20px;
}

h4:before {
  content: "/ ";
  position: absolute;
  left: 0;
  top: -2px;
}

.h4-light {
  font-size: $h4_fz;
  font-weight: 300;
  line-height: $h4_lh;
}

.h4-medium {
  font-size: $h4_fz;
  font-weight: 500;
  line-height: $h4_lh;
}

.h4-bold {
  font-size: $h4_fz;
  font-weight: 700;
  line-height: $h4_lh;
}

.h4-bold:before {
  font-weight: bold;
}

.h5,
h5 {
  font-size: $h5_fz;
  line-height: $h5_lh;
  color: $primary;
}

.h5-light {
  font-size: $h5_fz;
  font-weight: 300;
  line-height: $h5_lh;
}

.h5-medium {
  font-size: $h5_fz;
  font-weight: 500;
  line-height: $h5_lh;
}

.h5-bold {
  font-size: $h5_fz;
  font-weight: 700;
  line-height: $h5_lh;
  font-style: italic;
}

p,
a,
li,
td {
  font-size: $standard_text_fz;
  font-weight: 400;
  line-height: $standard_text_lh;
}

p:last-child {
  margin-bottom: 0;
}

a {
  color: $primary;
  transition: color ease .2s;
  display: inline-block;
}

a:hover {
  text-decoration: none;
  color: $primary-light;
}

.standard-text,
.standard-text * {
  font-size: $standard_text_fz;
  font-weight: 400;
  line-height: $standard_text_lh;
}

.standard-light-text,
.standard-light-text * {
  font-size: $standard_text_fz;
  font-weight: 300;
  line-height: $standard_text_lh;
}

.standard-medium-text,
.standard-medium-text * {
  font-size: $standard_text_fz;
  font-weight: 500;
  line-height: $standard_text_lh;
}

.standard-bold-text,
.standard-bold-text * {
  font-size: $standard_text_fz;
  font-weight: 700;
  line-height: $standard_text_lh;
}

.small-text,
.small-text * {
  font-size: $small_text_fz;
  font-weight: 400;
  line-height: $small_text_lh;
}

.small-light-text,
.small-light-text * {
  font-size: $small_text_fz;
  font-weight: 300;
  line-height: $small_text_lh;
}

.small-medium-text,
.small-medium-text * {
  font-size: $small_text_fz;
  font-weight: 500;
  line-height: $small_text_lh;
}

.small-bold-text,
.small-bold-text * {
  font-size: $small_text_fz;
  font-weight: 700;
  line-height: $small_text_lh;
}

.tiny-text,
.tiny-text * {
  font-size: $tiny_text_fz;
  font-weight: 400;
  line-height: $tiny_text_lh;
}

.tiny-light-text,
.tiny-light-text * {
  font-size: $tiny_text_fz;
  font-weight: 300;
  line-height: $tiny_text_lh;
}

.tiny-medium-text,
.tiny-medium-text * {
  font-size: $standard_text_fz;
  font-weight: 500;
  line-height: $tiny_text_lh;
}

.tiny-bold-text,
.tiny-bold-text * {
  font-size: $tiny_text_fz;
  font-weight: 700;
  line-height: $tiny_text_lh;
}

.italic {
  font-style: italic;
}

.white-text {
  color: $white;

  p,
  td,
  li,
  span,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $white;
  }
}

//
//@media (max-width: 991px) {
//  h2, .h2, .h2-light, .h2-bold {
//    font-size: $h3_fz;
//    line-height: $h3_fz;
//  }
//  h3, .h3, .h3-light, .h3-bold {
//    font-size: 26px;
//    line-height: 34px;
//  }
//
//  p,
//  .standard-text,
//  .standard-bold-text,
//  .standard-light-text {
//    font-size: 17px;
//  }
//}

@media (max-width: 991px) {

  h1,
  .h1 {
    font-size: 42px;
    line-height: 52px;
  }
}