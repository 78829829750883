.light-gray-bg {
  background-color: $lightest-gray;
}

.primary-bg {
  background-color: $winto_dark_blue;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.primary-light-bg {
  background-color: $winto_light_blue;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.secondary-bg {
  background-color: $yellow;
}

.dark-yellow-bg {
  background-color: $dark_yellow;
}

.red-bg {
  background-color: $red;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.green-bg {
  background-color: $green;
}

.turquoise-bg {
  background-color: $turquiose;
}

.purple-bg {
  background-color: $purple;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.dark-blue-bg {
  background-color: $dark_blue;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.light-green-bg {
  background-color: $light_green;
}

.lighter-green-bg {
  background-color: $lighter_green;
}

.orange-bg {
  background-color: $orange;
}

.lighter-blue-bg {
  background-color: $lighter_blue;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.fib-gray-bg {
  background-color: $fib_gray;
}

.ocher-bg {
  background-color: $ocher;
}


.t-primary-bg {
  background-color: $t_winto_dark_blue;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.t-primary-light-bg {
  background-color: $t_winto_light_blue;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.t-secondary-bg {
  background-color: $t_yellow;
}

.t-red-bg {
  background-color: $t_red;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.t-dark-yellow-bg {
  background-color: $t_dark_yellow;
}

.t-turquoise-bg {
  background-color: $t_turq;
}

.t-green-bg {
  background-color: $t_green;
}

.t-purple-bg {
  background-color: $t_purple;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.t-dark-blue-bg {
  background-color: $t_dark_blue;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.t-light-green-bg {
  background-color: $t_light_green;
}

.t-lighter-green-bg {
  background-color: $t_lighter_green;
}

.t-orange-bg {
  background-color: $t_orange;
}

.t-lighter-blue-bg {
  background-color: $t_lighter_blue;

  h1, h2, h3, h4, h5, td, li, p {
    color: $white;
  }
}

.t-light-gray-bg {
  background-color: $t_lightest_gray;

  h1,
  h1,
  h3,
  h4,
  h5,
  p,
  li,
  td {
    color: $primary  !important;
  }
}

.t-fib-gray-bg {
  background-color: $t_fib_gray;
}

.t-ocher-bg {
  background-color: $t_ocher;
}