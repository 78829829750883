.linklist {
  a {
    margin-right: 30px;
    max-width: 100%;
    width: max-content;
    white-space: nowrap;
  }

  h3 {
    margin-bottom: 40px;
    font-size: 36px;
    line-height: 45px;
  }

  .linklist-box {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }

}

@media (max-width: 767px) {
  .linklist {
    a {
      margin-right: auto;
      display: block;
      white-space: unset;
      height: auto;
    }

  }
}