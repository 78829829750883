.small-slider {

  h5 {
    margin-bottom: 70px;
    color: $primary;
  }
  
  a {
    text-decoration: none;
  }

  .swiper {
    overflow: visible;
    padding-bottom: 60px;
  }

  .swiper-buttons {
    top: unset;
    bottom: 80px;
    right: 0;

    .swiper-button-prev.swiper-button-disabled {
      opacity: 1;
    }
  }

  .swiper-slide {
    height: 370px;
    display: flex;
    overflow: hidden;
    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .text-wrapper {
      position: relative;
      height: 100%;
      width: 70%;
      z-index: 9;

      * {
        color: $white;
      }

      p {
        margin-bottom: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      h3 {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

    }
  }
  .inner-text {
    transform: skew(10deg);
  }

  .swiper-slide:nth-child(even){
    .inner-text {
      padding-left: 40px;
    }
    .text-wrapper {
      transform: skew(-10deg);
      margin-left: -35px;
      padding: 80px 60px 80px 30px;
    }
  }

  .swiper-slide:nth-child(odd){
    .inner-text {
      padding-right: 40px;
    }
    .text-wrapper {
      transform: skew(-10deg);
      padding: 80px 30px 80px 60px;
      margin-left: auto;
      margin-right: -35px;
    }
  }

  .swiper-pagination {
    padding: 0 15px;
  }

  .winto-btn {
    margin-top: 50px;
    margin-left: 0;
  }

}

@media (max-width: 991px) {
  .small-slider {
    .container {
      max-width: 100%;
    }
    .swiper-col {
      padding: 0;
    }
    .swiper {
      overflow: hidden;
      .text-wrapper {
        width: 92%;
      }
    }
    .swiper-slide:nth-child(even){
      .text-wrapper {
        padding: 80px 150px 80px 50px;
      }
    }

    .swiper-slide:nth-child(odd){
      .text-wrapper {
        padding: 80px 50px 80px 150px;
      }
    }
  }
}

@media (max-width: 767px) {
  .small-slider {
    .swiper-slide:nth-child(even){
      .text-wrapper {
        padding: 80px 100px 80px 50px;
      }
    }

    .swiper-slide:nth-child(odd){
      .text-wrapper {
        padding: 80px 50px 80px 100px;
      }
    }
  }
}

.zoom-slider {
  a {
    width: 100%;
  }
  p:last-child {
    margin-bottom: 20px;
  }
   img {
     width: 100%;
     height: 150px;
     object-fit: cover !important;
     max-height: 150px !important;
   }

  .swiper-button-next {
    margin-top: 0px;
    position: absolute;
    top: 50%;
    right: -40px;
    width: 45px;
    height: 45px;
    transform: translateY(-50%);
  }
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    left: -40px;
    width: 45px;
    height: 45px;
    transform: translateY(-50%);
    margin-top: 0px;
  }
}

@media (max-width: 991px) {
  .zoom-slider {
    .swiper-button-next {
      right: 10px;
    }
    .swiper-button-prev {

      left: 10px;
    }
  }
}


.pswp__img {
  object-fit: contain !important;
}

.pswp__custom-caption {
  background: $t_primary;
  font-size: 16px;
  color: #fff;
  width: calc(100% - 32px);
  max-width: 400px;
  padding-right: 15px;
  padding-left: 15px;
  position: absolute;
  left: 50%;
  bottom: 60px;
  transform: skew(-10deg) translateX(-50%);
}
.caption-text {
  color: #fff;
  transform: skew(10deg);
  display: block;
  padding: 8px 0;
}
.hidden-caption-content {
  display: none;
}

@media (max-width: 767px) {
  .pswp__custom-caption {
    bottom: 65px;
  }
}