.header-slider {
  height: 650px;
  padding-bottom: 100px;
  overflow: hidden;

  .swiper-slide {
    overflow-x: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .inner-text {
      transform: skew(10deg);
    }

    .text-wrapper {
      position: relative;
      width: calc(40vw + 50px);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      transform: skew(-10deg);
      padding: 100px 130px;
      margin-left: -50px;

      h1 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      p,
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $white;
      }
    }
  }

  .pagination-wrapper {
    position: relative;
    margin-top: 60px;
  }

}

.swiper-pagination {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: absolute;
  bottom: 40px;
  z-index: 99;

  .swiper-pagination-bullet {
    width: 120px;
    height: 5px;
    text-align: left;
    opacity: 1;
    background: transparent;
    border-radius: 0;
    border-bottom: 1px solid $primary;
  }

  .swiper-pagination-bullet-active {
    height: 6px;
    background: $primary;
    transition: all ease .3s;
  }

  .swiper-pagination-bullet:hover {
    height: 6px;
    background-color: $primary-light;
    border-bottom: 1px solid $primary-light;
    transition: all ease .3s;
  }
}


.swiper-buttons {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid $white;
  position: absolute;
  top: 325px;
  right: 80px;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;

  .swiper-button-prev,
  .swiper-button-next {
    color: $white;
    position: unset;
    margin: 0;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 28px;
  }
}

@media (max-width: 1400px) {
  .header-slider {
    .swiper-slide {
      .text-wrapper {
        padding: 100px 80px;
        width: calc(50vw + 50px);
      }
    }
  }
}

@media (max-width: 991px) {
  .swiper-buttons {
    display: none;
  }

  .swiper-pagination {
    justify-content: center;
  }

  .header-slider {
    .swiper-slide {
      display: flex;

      .text-wrapper {
        position: relative;
        width: 100%;
        height: max-content;
        transform: unset;
        padding: 20px 40px;
        margin-top: auto;
        margin-left: unset;

        .inner-text {
          transform: unset;
        }
      }
    }

  }
}