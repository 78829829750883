.simple-text {

  //h4:before {
  //  content: '';
  //  padding: 0;
  //  margin: 0;
  //}

  ul {
    list-style-type: none;
    padding-left: 30px;
    padding-top: 30px;

    li {
      position: relative;
    }

    li:before {
      content: '';
      position: absolute;
      left: -28px;
      top: 8px;
      background-color: $primary;
      width: 10px;
      height: 10px;
      transform: skew(-10deg);
    }
  }
}

.two-col {
  .col-lg-6:first-child {
    .text-wrapper {
      padding-right: 6px;
    }
  }
}

@media (max-width: 991px) {
  .simple-text {
    .col-12:first-child {
      margin-bottom: 40px;
    }
  }

  .two-col {
    .col-lg-6:first-child {
      .text-wrapper {
        padding-right: 0;
      }
    }
  }
}

.text-image {
  .order-1 {

    img,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    ul {
      padding-right: 3px;
    }
  }

  .order-2 {

    img,
    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
      padding-left: 3px;
    }

    ul {
      padding-left: 33px;
    }
  }
}