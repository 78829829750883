.partners {

  .col-12.d-flex {
    margin-bottom: -45px;
  }

  h4 {
    margin-bottom: 100px;
  }

  a {
    margin-bottom: 45px;
    margin-right: 30px;
  }

  a:last-child {
    margin-right: 0;
  }

  img {
    max-height: 60px;
    object-fit: contain;
  }
}

@media (max-width: 767px) {
  .partners {
    a {
      margin-right: unset;
    }

    h4 {
      margin-bottom: 50px;
    }

    .col-12:last-child {
      flex-direction: column;
    }
  }
}