$red: #a22a54;
$green: #a2c748;
$dark_yellow: #ffd800;
$turquiose: #47b0a3;
$purple: #6d43ab;
$winto_dark_blue: #253081;
$winto_light_blue: #005ca9;
$dark_blue: #006cb5;
$lighter_blue: #00ccff;
$light_green: #95c11f;
$lighter_green: #95c11f;
$orange: #f29400;
$yellow: #fad157;
$fib_gray: #706f6f;
$ocher: #e69900;


$black: #000;
$dark-gray: #494949;
$gray: #c8c8c8;
$light-gray: #ececec;
$lightest-gray: #f6f6f6;
$white: #fff;

$error: #FF0000;

$primary: $dark_yellow;
$primary-light: $black;
$secondary: $white;

$t_primary: rgba($primary, .8);
$t_primary_light: rgba($primary-light, .8);
$t_secondary: rgba($secondary, .8);
$t_winto_dark_blue: rgba($winto_dark_blue, .8);
$t_winto_light_blue: rgba($winto_light_blue, .8);
$t_red: rgba($red, .8);
$t_dark_yellow: rgba($dark_yellow, .8);
$t_yellow: rgba($yellow, .8);
$t_turq: rgba($turquiose, .8);
$t_green: rgba($green, .8);
$t_purple: rgba($purple, .8);
$t_dark_blue: rgba($dark_blue, .8);
$t_lighter_blue: rgba($lighter_blue, .8);
$t_light_green: rgba($light_green, .8);
$t_lighter_green: rgba($lighter_green, .8);
$t_orange: rgba($orange, .8);
$t_lightest_gray: rgba($lightest-gray, .8);
$t_fib_gray: rgba($fib_gray, .8);
$t_ocher: rgba($ocher, .8);