.contact-person {
  background-color: $dark-gray;

  * {
    color: $white;
  }

  p {
    line-height: 30px;
  }

  h2 {
    margin-bottom: 80px;
  }
  h4 {
    margin-bottom: 30px;
  }
  img {
    width: 310px;
    height: 185px;
    object-fit: cover;
    max-width: 100%;
  }
  .contact-buttons {
    a:first-child {
      margin-bottom: 10px;
    }
    a {
      text-decoration: none;
    }

    a:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}

.contact-person-module {
  padding: 80px 0;

  h4 {
    margin-bottom: 50px;
  }

  .contact-card {
    display: flex;
    justify-content: flex-start;
    background-color: #F6F6F6;
    margin-bottom: 40px;

    img {
      width: 190px;
      height: 190px;
      object-fit: cover;
    }

    .person-info {
      padding: 23px 30px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      .person-name {
        font-size: $standard_text_fz;
        line-height: $standard_text_lh;
        font-weight: 700;
        margin-bottom: 5px;
      }

      .person-role {
        font-size: $tiny_text_fz;
        line-height: $tiny_text_lh;
        margin-bottom: auto;
      }

      a {
        font-size: $standard_text_fz;
        line-height: $standard_text_lh;
        color: $standard_text_color;
        text-decoration: none;
      }

      .person-tel {
        pointer-events: none;
      }

      .person-email {
        margin-bottom: 5px;
      }

      svg,
      .bi-telephone:before {
        width: 24px;
        margin-right: 20px;
        color: $primary;
      }

      .bi-telephone:before {
        margin-left: 3px;
        margin-right: 17px;
      }
    }
  }

  .col-lg-6:nth-last-child(1),
  .col-lg-6:nth-last-child(2) {
    .contact-card {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 991px) {
  .contact-person {
    h2 {
      margin-bottom: 30px;
    }
    img {
      width: 100%;
      margin-bottom: 30px;
    }
    .contact-buttons {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
    }
  }

  .contact-person-module {
    .contact-card {
      .person-info {
        .person-tel {
          pointer-events: unset;
        }
        svg,
        .bi-telephone:before {
          margin-right: 5px;
        }
      }
    }
    .col-lg-6:nth-last-child(2) {
      .contact-card {
        margin-bottom: 40px;
      }
    }
  }
}

@media (max-width: 767px) {
  .contact-person-module {

    .contact-card {
      .person-info {
        padding: 10px 15px;
      }
      img {
        width: 130px;
        height: 130px;
      }
    }
    .col-lg-6 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

@media (max-width: 767px) {
  .contact-person-module {

    .contact-card {
      .person-info {
        padding: 10px 15px;
      }
      img {
        width: 130px;
        height: 130px;
      }
    }
    .col-lg-6 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}