.contact-form {
  .contact-form-footer {
    align-items: center;
    .winto-btn {
      margin-right: 15px;
    }
  }
}

@media (max-width: 991px) {
  .contact-form {
    .contact-form-footer {
      justify-content: space-between;
    }
  }
}