.discover {

  h4 {
    margin-bottom: 50px;
  }

  .discover-card.big-card {
    height: 520px;

    .text-wrapper {
      padding: 30px 120px 30px 100px;
    }
  }

  .discover-card {
    position: relative;
    height: 410px;
    margin-bottom: 24px;
    overflow: hidden;

    * {
      hyphens: none;
      word-break: keep-all;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .text-wrapper {
      height: 100%;
      padding: 30px 40px 30px 80px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      transform: skew(-10deg);
      flex-direction: column;
      width: calc(60% + 50px);
      margin-left: -50px;
      overflow: hidden;

      .text-inner {
        transform: skew(10deg);
      }

      h1, h3, h5, p {
        color: $white;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0;
        hyphens: none;
      }

      h1 {
        min-height: 52px;
      }

      h5 {
        margin: 30px 0;
      }

      p, h1 {
        -webkit-line-clamp: 1;
      }

      h3, h5 {
        -webkit-line-clamp: 4;
      }

      .winto-btn {
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .discover {
    .col-12:first-child {
      padding: 0 15px;
    }
    .col-12 {
      padding: 0;
    }
    .discover-card {
      height: 400px;
      margin-bottom: 40px;
      .text-wrapper {
        padding: 40px 40px 40px 80px ;
        width: 92%;

        h3 {
          font-size: 24px;
          line-height: 30px;
        }

        a {
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .discover.big-cards {
    .col-12:first-child {
      padding: 0;
    }
    .discover-card {
      height: 650px;
      display: flex;
      .text-wrapper {
        padding: 50px 20px;
        height: auto;
        margin-top: auto;
        transform: unset;
        width: 100%;
        position: relative;
        margin-left: 0;

        .text-inner {
          transform: unset;
        }
      }
    }
  }
}