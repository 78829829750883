.header-banner {
  height: 550px;
  width: 100%;
  position: relative;
  overflow: hidden;

  .img-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text-bg {
    width: calc(40vw + 50px);
    height: 100%;
    display: flex;
    align-items: center;
    transform: skew(-10deg);
    margin-left: -50px;
  }

  .text-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transform: skew(10deg);
    padding: 100px 130px;

    h1 {
      font-size: 55px;
      line-height: 60px;
      margin-bottom: 15px;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $white;
      hyphens: none;
    }

    h1 {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@media (max-width: 1400px) {
  .header-banner {
    .text-bg {
      width: calc(50vw + 50px);
    }

    .text-wrapper {
      padding: 100px 80px;
    }
  }
}

@media (max-width: 991px) {
  .header-banner {
    display: flex;
    height: auto;

    .text-bg {
      width: 100vw;
      transform: unset;
      position: relative;
      height: auto;
      margin-top: auto;
      margin-left: unset;
    }

    .text-wrapper {
      transform: unset;

      h1 {
        font-size: 50px;

      }
    }
  }
}

.banner-img {
  img {
    width: 100%;
    object-fit: cover;
  }
}