.video-slider {
  overflow: hidden;
  h3 {
    margin-bottom: 30px;
  }
  .swiper {
    overflow: visible;
    padding-bottom: 200px;
  }
  .swiper-slide {
    height: 530px;
  }
  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .custom-video-area {
    height: 100%;
  }

  .swiper-buttons {
    top: 225px;
  }

  .bi-play {
    font-size: 50px;
  }
  .winto-btn {
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fa-maximize {
    color: $white;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 27px;
  }
}