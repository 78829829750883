.quote {

  .quote-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 52px 135px;
    position: relative;
    margin-bottom: 30px;
  }

  .fa-quote-right {
    position: absolute;
    font-size: 60px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    color: $primary;
  }

  h5,
  p {
    text-align: center;
  }

  h5 {
    color: $primary;
    hyphens: none;
  }

  p {
    color: #5F5E5C;
  }
}

@media (max-width: 767px) {
  .quote {
    .quote-card {
      padding: 30px;
    }
  }
}