@import "contactForm";
@import "visitorRegistrationForm";

form .col-12 {
  margin-bottom: 60px;
}

.error-msg {
  margin-top: 60px;
}

input,
textarea {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  color: $dark-gray;
  border-bottom: 2px solid $gray;
  width: 100%;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  padding-bottom: 20px;
}

.select2 {
  background-color: transparent;
  color: $dark-gray;
  border-bottom: 2px solid $gray;
  width: 100% !important;
}

.select-arrow {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: $primary;
  font-size: 24px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.form-error {

  input,
  textarea {
    border-color: $error;
  }

  label {
    color: $error !important;
  }
}

.checkbox-wrapper.form-error {
  .checkbox-border {
    border-color: $error;
  }

  label {
    color: $dark-gray !important;
  }
}

.form_error {
  border-color: $error;
}

#form_response_wrapper span {
  padding: 15px;
}

.error-msg {
  display: none;
}

.form-error+.error-msg {
  color: $white;
  margin-top: -50px;
  display: block;
  background-color: $error;
  padding: 0 5px;
  width: max-content;
}

.input-group {
  position: relative;
  transition: background-color 300ms;
  padding-top: 10px;

  input,
  textarea,
  .select2 {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: transparent;
    transition: border 300ms;
    padding: 20px 16px 6px;
    font-size: 20px;
  }

  input:focus,
  textarea:focus,
  select {
    outline: none;
  }

  input:focus+label,
  input.is-valid+label {
    transform: translateY(-100%) scale(0.75);
  }

  textarea:focus+label,
  textarea.is-valid+label {
    transform: translateY(-60%) scale(0.75);
  }

  textarea+label {
    top: 20px;
    transform: unset;
  }

  textarea:focus,
  textarea.is-valid {
    padding-top: 0;
    margin-top: 20px;
  }

  .select2+label {
    pointer-events: none;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1), top 500ms, -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  }

  .select2-container--focus+label,
  .select2-container.is-valid+label {
    transform: translateY(-100%) scale(0.75);
    border-color: $primary;
    transition: border-bottom-color ease 300ms;
  }

  .select2:hover {
    border-color: $primary;
    transition: border-bottom-color ease 300ms;
  }

  .select2-container--open {
    border-color: $primary;
    transition: border-bottom-color ease 300ms;
  }

  label {
    color: $dark-gray;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left top;
    user-select: none;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1), top 500ms;
    font-size: 20px;
    font-weight: 400;
    left: 5px;
  }
}

.input-group-radio {
  padding-bottom: 10px;
}

.select2-dropdown {
  border: 2px solid $primary;
  border-radius: 0;

  .select2-search__field {
    border-bottom: 2px solid $primary;
    padding: 6px !important;
  }

  .select2-search--dropdown {
    padding: 0 !important;
  }
}

.select2-results__option:first-child {
  padding: 0 !important;
}

textarea::placeholder,
input::placeholder {
  color: $dark-gray;
}

.input-group:hover {
  label {
    color: $primary;
    cursor: text;
  }

  input,
  textarea {
    border-color: $primary;
    transition: border-bottom-color 300ms;
  }
}

input[type="text"]:focus,
textarea:focus {
  border-color: $primary;

  +label {
    color: $dark-gray !important;
  }
}

.checkbox-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .checkbox-border {
    min-width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $gray;
    margin-right: 10px;
    margin-top: 2px;
    position: relative;

    .checkbox-border.checked {
      border-color: $primary;
    }

    .custom-checkbox {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    input:checked+.custom-checkbox {
      background-color: $primary;
    }
  }

  label {
    color: $dark-gray;
    line-height: 28px;
    cursor: pointer;
    font-size: 17px;

    a {
      margin-bottom: -4px;
      color: $dark-gray;
      text-decoration: underline;
      font-size: 17px;
    }

    a:after {
      width: 0;
      height: 0;
      transform: none;
    }
  }

  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    cursor: pointer;
  }
}

// CUSTOM RADIO BUTTONS
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  line-height: 30px;
  display: inline-block;
  color: $dark-gray;
  margin-right: 20px;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 2px solid #ccc;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:before {
  border: 2px solid $primary !important;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 18px;
  height: 18px;
  background: $primary;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

#search_form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #c8c8c8;

  input {
    font-size: 55px;
    line-height: 62px;
    padding-bottom: 30px;
    border-bottom: none;
  }

  input::placeholder {
    color: $primary;
    font-weight: bold;
    font-style: italic;
  }

  .search-btn {
    background-color: transparent;
    border: none;
    margin-bottom: auto;

    .bi-search {
      color: $primary;
      font-size: 50px;
    }
  }
}

.active-search-page {
  border-bottom: 2px solid $secondary;
  padding-bottom: 10px;
  margin-top: 10px;
}

.faq-module {

  a,
  p {
    color: $standard_text_color;
    text-decoration: none;
    font-size: 17px;
  }

  a:hover {
    color: $primary-light;
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  #search_form {
    input {
      font-size: $h5_fz;
      line-height: $h5_lh;
      padding-bottom: 30px;
    }

    .search-btn {
      .bi-search {
        font-size: $h5_fz;
      }
    }
  }
}

.typeform1-fld,
.typeform2-fld {
  display: none;
}

.radio-wrapper.form-error {
  label {
    color: $standard_text_color !important;
  }
  label:before {
    border-color: $error;
  }
}

.pw-toggle {
  position: absolute;
  right: 16px;
  top: 20px;
}

.pw-fld {
  input {
    padding-right: 45px;
  }
  .fa-eye {
    display: none;
  }
}