.premium-partner-wrapper {
    padding: 0 100px;
    overflow: hidden;
    position: relative;


    .premium-partner {
        height: 175px;
        padding-bottom: 50px;
        overflow: hidden;
        width: 100%;

        .swiper-slide {
            overflow-x: hidden;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;

            a {
                width: max-content;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: max-content;
                height: 100%;
                object-fit: contain;
            }

            .inner-text {
                transform: skew(10deg);
            }

            .text-wrapper {
                position: relative;
                width: calc(40vw + 50px);
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                transform: skew(-10deg);
                padding: 100px 130px;
                margin-left: -50px;

                h1 {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                p {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                p,
                h1,
                h2,
                h3,
                h4,
                h5 {
                    color: $white;
                }
            }
        }

        .pagination-wrapper {
            position: relative;

            margin-top: 60px;
        }

    }

    .swiper-pagination {
        display: none;
        align-items: flex-end;
        position: absolute;
        bottom: 40px;
        z-index: 99;
        justify-content: center;

        .swiper-pagination-bullet {
            width: 25px;
            height: 5px;
            text-align: left;
            opacity: 1;
            background: transparent;
            border-radius: 0;
            border-bottom: 1px solid $primary;
        }

        .swiper-pagination-bullet-active {
            height: 6px;
            background: $primary;
            transition: all ease .3s;
        }

        .swiper-pagination-bullet:hover {
            height: 6px;
            background-color: $primary-light;
            border-bottom: 1px solid $primary-light;
            transition: all ease .3s;
        }
    }


    .swiper-button-prev,
    .swiper-button-next {
        width: 40px;
        height: 40px;
        color: $gray;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;

        &:after {
            font-size: 25px;
        }
    }

    .swiper-button-prev {
        left: 28px;
    }

    .swiper-button-next {
        right: 28px;
    }
}





@media (max-width: 768px) {
    .premium-partner-wrapper {
        padding: 0 27px;

        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }

        .swiper-pagination {
            // display: none;
        }

        .premium-partner {
            .swiper-slide {
                display: flex;

            }


        }
    }
}

@media (max-width: 1400px) {
    .premium-partner {
        .swiper-slide {
            .text-wrapper {
                padding: 100px 80px;
                width: calc(50vw + 50px);
            }
        }
    }
}