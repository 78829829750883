.company-details-short-text {
  p {
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
    color: $primary;
  }
}

.company-details-long-text {
  p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: $dark_gray;
    margin-bottom: 40px;
  }
}


.company-search-details-header {
  .company_booth_nr {
    margin-top: 10px;
    color: $dark-gray;
  }
}


@media (max-width: 991px) {
  .company-search-details-header {
    .company_booth_nr {
      margin-bottom: 30px;

    }
  }
}