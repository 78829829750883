.accordion {

  .accordion-item,
  .accordion-button {
    background-color: $lightest-gray;
  }

  .accordion-item {
    margin-bottom: 20px;
    border: none;
  }

  .accordion-button {
    font-size: 20px;
    font-weight: 700;
    font-style: italic;
    color: #939393;
    border: none;
    transition: all ease .3s;
    line-height: 1;
  }
  
  .accordion-button:hover {
    background-color: $lightest-gray;
    color: $primary-light;
    transition: all ease .3s;

    .circle-border {
      border-color: $primary-light;
      transition: all ease .3s;
      .fa-plus,
      .fa-minus {
        transition: all ease .3s;
        color: $primary-light;
      }
    }
  }

  .accordion-body {
    font-size: 20px;
    color: $dark-gray;
    padding: 25px 70px 60px 70px;
  }

  .accordion-button:after {
    display: none;
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent;
  }
  .accordion-button:not(.collapsed) {
    outline: none;
    box-shadow: none;
  }


  .circle-border {
    border-radius: 50%;
    border: 2px solid $primary;
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    transition: all ease .3s;
  }

  .accordion-button {
    .fa-plus {
      font-size: 16px;
      display: none;
    }
    .fa-minus {
      font-size: 16px;
    }
    .fa-plus,
    .fa-minus {
      transition: all ease .3s;
    }
  }

  .collapsed.accordion-button {
    .fa-minus {
      display: none;
    }
    .fa-plus {
      display: block;
    }
  }

}

@media (max-width: 767px) {
  .accordion {
    .accordion-body {
      padding: 15px;
    }
  }
}